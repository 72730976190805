import React from "react"
import PropTypes from "prop-types"
import { kebabCase } from "lodash"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"

import Heading from "../../../components/Heading/Heading"
import Layout from "../../../components/Layout/Layout"
import Section from "../../../components/Section/Section"
import Segment from "../../../components/Segment/Segment"


const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  }
}) => (
  <Layout>
    <Helmet title={`Blog tags | ${title}`} />
    <Section>
      <Segment>
        <Heading level={1} modifiers={["major"]}>Blog Tags</Heading>
        <ul className="tag-list">
          {group.map(tag => (
            <li key={tag.fieldValue} className="tag-list__item">
              <Link className="tag-list__link" to={`/blog/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Segment>
    </Section>
  </Layout>
)


TagsPage.propTypes = {
  data: PropTypes.object,
}


export default TagsPage


export const tagPageQuery = graphql`
  query TagsQuery($deployDate: Date!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "blog-post"},
          visible: {eq: true},
          date: {lte: $deployDate}
        }
      },
      sort: {fields: frontmatter___date},
      limit: 1000
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
